<template>
  <v-radio-group
    v-model="selectedVisibility"
    class="visibility-radio-group mt-0 pt-0">
    <CyInputsRadioCard
      v-for="(visibilityObj, name) in $static.visibilities"
      :key="name"
      :aria-label="name"
      :value="name"
      @click="$emit('update:visibility', name)">
      <template #label>
        <div class="visibility__wrapper">
          <v-icon
            :class="['visibility__icon', `${name}-color`]"
            :color="visibilityObj.color"
            size="24">
            {{ visibilityObj.icon }}
          </v-icon>
          <div class="visibility__text">
            <strong class="d-block black--text">
              {{ $t(`stacks.visibility.${name}`) }}
            </strong>
            <p class="mb-0">
              {{ $t(`stacks.visibility.${name}Hint`) }}
            </p>
          </div>
        </div>
      </template>
    </CyInputsRadioCard>
  </v-radio-group>
</template>

<script>
import CyInputsRadioCard from '@/components/inputs/radio-card.vue'
import { VISIBILITIES } from '@/utils/helpers/stacks'

export default {
  name: 'CyWizardStackVisibilityList',
  components: {
    CyInputsRadioCard,
  },
  props: {
    visibility: {
      type: String,
      default: 'local',
    },
  },
  computed: {
    $static: () => ({
      visibilities: VISIBILITIES,
    }),
    selectedVisibility: {
      get () {
        return this.visibility
      },
      set (value) {
        this.$emit('update:visibility', value)
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.visibility-radio-group > ::v-deep .v-input__control {
  flex-grow: 1;
}

::v-deep .v-label {
  &:focus-within {
    box-shadow: none;
  }
}

::v-deep .v-messages {
  display: none;
}

.visibility {
  font-size: 12px;
  line-height: 1.2em;

  &__wrapper {
    display: flex;
    gap: $spacer * 4;
  }

  &__icon {
    width: 32px;
    height: 32px;
    padding: $spacer;
    border: 1px solid cy-get-color("white");
    border-radius: 4px;

    &.local-color,
    &.shared-color {
      background-color: cy-get-color("grey", "light-1");
    }

    &.hidden-color {
      background-color: cy-get-color("accent", "light-3");
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
  }

}
</style>
