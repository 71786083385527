<template>
  <v-select
    v-model="selectedTeam"
    :items="availableOptions"
    :label="$t('stacks.maintainer.label')"
    :menu-props="{ offsetY: true }"
    item-text="name"
    item-value="canonical">
    <template #selection="{ item }">
      <span class="d-flex align-center">
        <CyAvatar
          :class="['mr-1 my-1', { 'no-maintainer': !item.canonical }]"
          :item="item"/>
        {{ item.name }}
      </span>
    </template>
    <template #item="{ item }">
      <v-list-item-avatar
        class="mr-1 my-0"
        :size="34">
        <CyAvatar
          :class="{ 'no-maintainer': !item.canonical }"
          :item="item"
          sm/>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>
          {{ item.name }}
        </v-list-item-title>
      </v-list-item-content>
    </template>
  </v-select>
</template>

<script>
export default {
  name: 'CyWizardStackMaintainerSelector',
  components: {},
  props: {
    teams: {
      type: Array,
      required: true,
    },
    selected: {
      type: [String, null],
      default: null,
    },
  },
  computed: {
    $static () {
      return {
        noMaintainer: {
          canonical: null,
          name: this.$t('stacks.maintainer.noMaintainer'),
          icon: 'group',
        },
      }
    },
    selectedTeam: {
      get () {
        return this.selected
      },
      set (value) {
        this.$emit('update:selected', value)
      },
    },
    availableOptions () {
      return [
        this.$static.noMaintainer,
        ...this.teams,
      ]
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .cy-avatar {
  border-radius: 4px !important;

  &__initials {
    font-size: $font-size-default !important;
    font-weight: $font-weight-bolder !important;
  }

  &.no-maintainer {
      background-color: transparent !important;

    .v-icon {
      border: none;
      background-color: transparent;
      color: cy-get-color("black") !important;
      font-size: $spacer-6 !important;
    }
  }
}

::v-deep .v-label {
  color: cy-get-color("grey", "dark-2");
}
</style>
